<template>
  <page>
    <div>
      <div class="phoneTip">为了您的账户安全，请绑定手机号</div>
      <div>
        <van-field class='f_flex f_a_c telField' v-model="phone" type="tel" label-width='24' placeholder="请输入手机号">
          <div slot="label" class="f_flex f_a_c f_j_c">
            <img class="labelIcon" src="../../assets/login_phone.png" alt="">
          </div>
        </van-field>

        <van-field class="f_flex f_a_c telField" placeholder="短信验证码" v-model="code" label-width="24" type="tel">
          <div slot="label" class="f_flex f_a_c f_j_c">
            <img class="labelIcon" src="../../assets/login_safe.png" alt="">
          </div>
          <template #button>
            <count-down class="theme_c" :state='codeState' @change='countDownFn'></count-down>
          </template>
        </van-field>

        <van-field class='f_flex f_a_c telField' v-model="pwd" :type="pwdType" label-width='24' placeholder="设置密码">
          <div slot="label" class="f_flex f_a_c f_j_c">
            <img class="labelIcon" src="../../assets/login_lock.png" alt="">
          </div>
          <template #button>
            <toggle-pwd :inputType="pwdType" @change='changeInputType'></toggle-pwd>
          </template>
        </van-field>

        
        <van-field class="f_flex f_a_c telField" placeholder="公司码（选填）" v-model="companyCode" label-width="24" type="tel">
          <div slot="label" class="f_flex f_a_c f_j_c">
            <img class="labelIcon" src="../../assets/login_code.png" alt="">
          </div>
        </van-field>
        <div></div>
      </div>

      <button 
        class="btn_radius margin_auto register_btn " 
        :class="[phone && code && pwd ? 'theme_bg' : 'theme_dis_bg']">
        绑定
      </button>
      <div class="f_flex f_a_c f_j_c">
        <div class="protocol_txt" style="color: #9B9B9B;">注册即代表同意 </div>
        <div class="protocol_txt theme_c">《用户服务协议》</div>
        <div class="protocol_txt" style="color: #9B9B9B;">及</div>
        <div class="protocol_txt theme_c">《隐私政策》</div>
      </div>
    </div>
  </page>
</template>

<script>
import { Field } from 'vant';
import smgApi from '@/api/other'
import loginApi from '@/api/user'
import countDown from '@/components/countDown/index.vue'
import togglePwd from './togglePwd.vue'
import getAuth from '@/common/mixins/getAuth.js'

export default {
  data: ()=> ({
    phone: '',
    code: '',
    companyCode: '',
    pwd: '',
    pwdType: 'password',
    codeState: false
  }),
  components: {
    'van-field': Field,
    'count-down': countDown,
    togglePwd
  },
  mixins: [getAuth],
  methods: {
    countDownFn() {
      this.codeState = true
      setTimeout(()=> {
        this.codeState = false
      }, 60000)
    },
    changeInputType(e) {
      this.pwdType = e
    },

    sendCode() {
      if (this.phone.match(/^1\d{10}$/g)) {
        this.$toast.loading({
          message: '正在发送...',
          forbidClick: true,
        });
        smgApi.send({
          phone: this.phone
        }).then(res=> {
          this.$toast.success(res.data.msg)
          this.countDownFn()
        }).finally(()=> {
          setTimeout(()=> {
            this.$toast.clear()
          }, 1500)
        })
      } else {
        this.$toast('手机号不正确')
      }
    },
    verifyFn() {
      const obj = {
        phone: {
          test: /^1\d{10}$/,
          msg: '手机号不正确'
        },
        code: {
          test: /\d{4,8}/,
          msg: '验证码不正确'
        },
        pwd: {
          test: /\S/,
          msg: '请设置密码'
        }
      }
      let b = true
      for(let [k,v] of Object.entries(obj)) {
        if (!(this[k] && v.test.test(this[k]))) {
          this.$toast(v.msg)
          b = false
          return b
        }
      }
      return b
    },
    registerFn() {
      const b = this.verifyFn()
      if (b) {
        const data = {
          phone: this.phone,
          password: this.pwd,
          verifyCode: this.code,
        }
        if (this.companyCode) {
          data.companyCode = this.companyCode
        }
        loginApi.register(data).then(res => {
          if (res.data) {
            this.$global.setItem({
              JSESSIONID: res.data.JSESSIONID,
              userInfo: res.data.userInfo
            })
            this.$router.push('/home')
            // this.$router.push('/home')
          } else {
            this.$toast(res.errorMsg)
          }
        })
      }
    }
  },
  created() {
    const {phone} = this.$route.query
    if (phone) {
      this.phone = phone
    }
  }
}
</script>

<style lang="less" scoped>
.phoneTip {
  font-size: 13px;
  color: #666;
  margin-top: 39px;
  margin-bottom: 15px;
  padding-left: 20px;
}
.labelIcon {
  width: 14px;
}
.fieldCell {
  height: 58px;
  font-size: 15px;
  border-bottom: 1px solid #E4E4E4;
  padding: 0;
}
.fieldLabel {
  color: #BABABA;
  font-size: 15px;
}
.register_btn {
  width: 70%;
  margin-top: 34px;
  margin-bottom: 19px;
}
.protocol_txt {
  font-size: 11px;
}
</style>